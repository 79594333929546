import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import Loader from '../components/Loader';

const LoginPage = lazy(() => import('../pages/auth/Login'));
const ForgotPassword = lazy(() => import('../pages/auth/ForgotPassword'));
const SearchPage = lazy(() => import ('../pages/Search/Search'));
const SearchResultsPage = lazy(() => import ('../pages/Search/SearchResult'));
const PaxDetailsPage = lazy(() => import ('../pages/Checkout/paxDetails'));
const ConfirmationDetailsPage = lazy(() => import ('../pages/Confirmation/Confirmation'));
const RegistrationDetailsPage = lazy(() => import ('../pages/auth/Register'));
const MyTripsPage = lazy(() => import ('../pages/Search/Mytrips'));
const MyProfilePage = lazy(() => import ('../pages/Search/Myprofile'));

function Routes() {
  let authenticated = false;
  try {
    authenticated = JSON.parse(localStorage.getItem('persist:access-user')).authToken.length > 0 ? true : false;
  } catch(e) {

  }
  
  return (
    <div>
      <Router>
        <div className="container">
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route exact path="/">
                <Redirect to="/search" />
              </Route>
              <Route exact path="/auth/login" component={LoginPage} />
              <Route exact path="/auth/forgot-password" component={ForgotPassword} />
              <Route exact path="/auth/register" component={RegistrationDetailsPage} />
              < Route exact path = "/search" component = {SearchPage} />
              < Route exact path = "/search-results" component = {SearchResultsPage} />
              < Route exact path = "/pax-details" component = {PaxDetailsPage} />
              < Route exact path = "/confirmation-section" component = {ConfirmationDetailsPage} />
              < Route exact path = "/my-trips" component = {MyTripsPage} />
              < Route exact path = "/my-profile" component = {MyProfilePage} />
            </Switch>
          </Suspense>
        </div>
      </Router>
    </div>
  );
}

export default Routes;